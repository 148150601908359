import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { FloorplanType } from "./Types";
import useGTM from "./hooks/useGTM";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  moveDate: null | string;
  note: string;
}

const BookingForm = ({
  appointmentDate,
  apartmentDetails,
  handleResetAfterSubmittingForm,
  clientId,
}: {
  appointmentDate?: string;
  apartmentDetails?: FloorplanType | null;
  handleResetAfterSubmittingForm: () => void;
  clientId?: string | number;
}) => {
  const [loaderActive, setLoaderActive] = useState(false);
  const pushEventToDataLayer = useGTM();
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    moveDate: null,
    note: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email address is required"),
    phone: Yup.string().matches(
      /^[0-9]{10}$/, // Change this regex pattern based on your requirements
      "Phone number must be exactly 10 digits"
    ),
    moveDate: Yup.date().nullable(),
    note: Yup.string().max(500),
  });

  const handleSubmit = (data: FormData, { resetForm }) => {
    setLoaderActive(true);

    if (appointmentDate && isValidISODateString(appointmentDate)) {
      const payload = {
        appointmentDate: appointmentDate,
        bathrooms: apartmentDetails?.bathsCount,
        bedrooms: apartmentDetails?.bedsCount,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phone,
        moveDate: data.moveDate && dayjs(data.moveDate).format("YYYY-MM-DD"),
        note: data.note,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/schedule`, payload, {
          params: { smpClientId: clientId },
        })
        .then((result) => {
          setLoaderActive(false);
          toast.success("Tour booked successfully! Please check you email.");
          pushEventToDataLayer(clientId + " booked_tour", {
            event_category: "button",
            event_action: "click",
            event_label: "booktour_button",
            page_path: `/video/floorplans/floorplan/${apartmentDetails?.name}/book`,
            page_client_id: clientId,
            page_lead_id: result.data?.leadId,
          });
          handleResetAfterSubmittingForm();

          navigate(location.pathname.split("/").slice(0, -1).join("/"));
        })
        .catch((error) => {
          console.log({ error });
          setLoaderActive(false);
          handleResetAfterSubmittingForm();
          toast.error("Error occurred. Please try again later...");
          resetForm();
        });
    }
  };

  const isValidISODateString = (dateString: string) => {
    const isoRegex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
    return isoRegex.test(dateString);
  };

  return (
    <div>
      {loaderActive ? (
        <div
          style={{
            width: "100%",
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-formik-container">
              <label className="w-formik-label">First name *</label>
              <ErrorMessage name="firstName" component="span" />
              <Field
                name="firstName"
                placeholder="eg. John"
                className="w-input-formik-field"
              />

              <label className="w-formik-label">Last name *</label>
              <ErrorMessage name="lastName" component="span" />
              <Field
                name="lastName"
                placeholder="eg. Smith"
                className="w-input-formik-field"
              />

              <label className="w-formik-label">Email *</label>
              <ErrorMessage name="email" component="span" />
              <Field
                name="email"
                placeholder="eg. test.123@domain.co"
                className="w-input-formik-field"
              />

              <label className="w-formik-label">Phone number</label>
              <ErrorMessage name="phone" component="span" />
              <Field
                name="phone"
                placeholder="eg. 5551234567"
                className="w-input-formik-field"
              />

              <label className="w-formik-label">Move in date</label>
              <ErrorMessage name="moveDate" component="span" />
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "10px",
                  color: "#000",
                  fontWeight: "400",
                  boxShadow:
                    "0 2px 6px -1px rgb(0 0 0 / 0.2), 0 2px 3px -2px rgb(0 0 0 / 0.1)",
                  borderRadius: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Field name="moveDate">
                    {({ field }) => (
                      <DatePicker
                        value={field.value}
                        onChange={(newValue) => {
                          setFieldValue("moveDate", newValue);
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "medium",
                          },
                          field: { clearable: true },
                        }}
                        minDate={new Date()}
                      />
                    )}
                  </Field>
                </LocalizationProvider>
              </div>
              <label className="w-formik-label">Note</label>
              <ErrorMessage name="note" component="span" />
              <Field
                name="note"
                placeholder="eg. I would like to ask..."
                className="w-input-formik-field"
                component="textarea"
                style={{
                  paddingTop: "5px",
                  maxWidth: "100%",
                  minWidth: "100%",
                  minHeight: "40px",
                  maxHeight: "150px",
                }}
              />

              <button
                type="submit"
                style={{
                  marginTop: "10px",
                  border: "1px solid var(--w-primary-color)",
                  background: "var(--w-primary-color)",
                  color: "#fff",
                  width: "40%",
                  alignSelf: "center",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px",
                  marginBottom: 0,
                }}
                className="w-primary-button"
              >
                Book tour
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default BookingForm;
