import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dropdown from "./Dropdown";
import dayjs, { Dayjs } from "dayjs";
import { ClientData } from "./Types";
import { formatNumber } from "./helpers/formatNumber";

const FilterFloorplans = ({
  priceFilter,
  bedFilter,
  bathFilter,
  handleBedFilter,
  handleBathFilter,
  handlePriceFilter,
  isSm,
  clientData,
}: {
  priceFilter: number[];
  bedFilter: number;
  bathFilter: number;
  handleBedFilter: (value: number) => void;
  handleBathFilter: (value: number) => void;
  handlePriceFilter: (value: number[]) => void;
  isSm: boolean;
  clientData: ClientData;
}) => {
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(new Date()));

  const handleChangePrice = (event: Event, newValue: number | number[]) => {
    handlePriceFilter(newValue as number[]);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: "15px",
          display: "flex",
          flexDirection: isSm ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", width: isSm ? "90%" : "50%" }}>
          <Dropdown
            item={bedFilter}
            itemName={"beds"}
            handleItem={handleBedFilter}
            itemArray={["Any", 1, 2]}
          />
          <Dropdown
            item={bathFilter}
            itemName={"baths"}
            handleItem={handleBathFilter}
            itemArray={["Any", 1, 2]}
          />
        </div>
        <div
          style={{
            width: isSm ? "90%" : "50%",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  label="Select a move in date (optional)"
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                  minDate={dayjs()}
                />
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      {/* slider */}
      <div style={{ width: isSm ? "90%" : "100%" }}>
        <div
          style={{
            marginTop: "30px",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: "300",
          }}
        >
          What is your target rent?
        </div>
        <div
          style={{
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              width: "95%",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            <Slider
              getAriaLabel={() => "Price range"}
              value={priceFilter}
              onChange={handleChangePrice}
              valueLabelDisplay="auto"
              min={clientData?.property.floorplans.range.min}
              max={clientData?.property.floorplans.range.max}
              sx={{ color: "var(--w-primary-color)", fontSize: "15px" }}
            />
          </Box>
        </div>
      </div>
      <div
        style={{
          width: isSm ? "90%" : "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: "15px",
          }}
        >
          ${formatNumber(priceFilter[0])}
        </div>
        <div
          style={{
            fontSize: "15px",
            fontWeight: 500,
          }}
        >
          ${formatNumber(priceFilter[1])}
        </div>
      </div>
    </>
  );
};

export default FilterFloorplans;
