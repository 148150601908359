import React, { useState } from "react";
import { ClientData } from "./Types";
import { Outlet, useNavigate } from "react-router-dom";
import { widgetStyle, shapeWidgetStyle, chatStyle, hoverStyle } from "./Styles";
import CircleComponent from "./CircleComponent";
import useGTM from "./hooks/useGTM";

const Home = ({ clientData }: { clientData?: ClientData }) => {
  const [hoveredChat, setHoveredChat] = useState(false);
  const [hoveredVideo, setHoveredVideo] = useState(false);
  const navigate = useNavigate();
  const pushEventToDataLayer = useGTM();

  if (!clientData) {
    return;
  }

  return (
    <div>
      {clientData && clientData.ui.videoWidget.isActive && (
        <>
          <div
            className="w-filter-dropshadow"
            style={{
              ...widgetStyle,
              ...{
                filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))",
                animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) 5",
                left: clientData?.ui.position === "left" ? "35px" : "unset",
                right: clientData?.ui.position === "right" ? "35px" : "unset",
                overflow: "visible",
              },
            }}
          >
            <div
              style={{
                ...widgetStyle,
                ...shapeWidgetStyle,
                ...{
                  backgroundColor: "var(--w-primary-color)",
                  left: clientData?.ui.position === "left" ? "35px" : "unset",
                  right: clientData?.ui.position === "right" ? "35px" : "unset",
                  position: "static",
                },
              }}
            ></div>
          </div>
          <div
            style={{
              ...widgetStyle,
              ...shapeWidgetStyle,
              ...(hoveredVideo && hoverStyle),
              ...{
                backgroundColor: "#fff",
                animation: "border-turns-primary-from-white 0.5s 3.8s both",
                border: "none",
                left: clientData?.ui.position === "left" ? "35px" : "unset",
                right: clientData?.ui.position === "right" ? "35px" : "unset",
              },
            }}
            onClick={() => {
              navigate("/video");
              pushEventToDataLayer(clientData?.id + " widget_opened", {
                event_category: "button",
                event_action: "click",
                event_label: "video_button",
                page_path: "/video",
                page_client_id: clientData?.id,
              });
            }}
            onMouseEnter={() => setHoveredVideo(true)}
            onMouseLeave={() => setHoveredVideo(false)}
          >
            {/* gradients */}
            <div
              className="w-gradients"
              style={{
                width: "92%",
                height: "92%",
                position: "absolute",
                overflow: "hidden",
                animation: "wh-returns-original 0.5s 4s both",
              }}
            >
              <div
                style={{
                  ...shapeWidgetStyle,
                  ...{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1))",
                    opacity: 0.8,
                    zIndex: 30,
                    top: 0,
                    left: 0,
                  },
                }}
              />
              <div
                style={{
                  ...shapeWidgetStyle,
                  ...{
                    position: "absolute",
                    zIndex: "9999",
                    width: "100%",
                    height: "100%",
                    backgroundImage: hoveredVideo
                      ? `linear-gradient(to bottom, var(--w-primary-color), color-mix(in srgb, var(--w-primary-color) 10%, transparent) 70%)`
                      : "",
                    opacity: 0.8,
                    transition: "all 0.3s ease-in-out 0.1s",
                  },
                }}
              />
            </div>

            <CircleComponent
              videoUrl={clientData?.property.coverVideo}
              posterUrl={clientData?.property.coverImage}
            />

            <div className="w-loader"></div>

            {/* text */}
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                color: "#FFFFFF",
                zIndex: 30,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  opacity: hoveredVideo ? "1" : "0",
                  transform: hoveredVideo
                    ? "translateY(0%)"
                    : "translateY(-120%)",
                  transition: "all 0.3s ease-in-out",
                  padding: "10px",
                  fontSize: "14px",
                }}
              >
                {clientData?.ui.videoWidget.triggerTextHover}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                color: "#FFFFFF",
                zIndex: 30,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  opacity: hoveredVideo ? "0" : "1",
                  transform: hoveredVideo
                    ? "translateY(120%)"
                    : "translateY(0%)",
                  transition: "all 0.3s ease-in-out",
                  padding: "10px",
                  fontSize: "14px",
                }}
              >
                {clientData?.ui.videoWidget.triggerText}
              </div>
            </div>
          </div>
        </>
      )}
      {clientData && clientData.ui.chatWidget.isActive && (
        <div
          style={{
            ...chatStyle,
            ...(hoveredChat && hoverStyle),
            ...{
              backgroundColor: "var(--w-primary-color)",
              backgroundImage: `linear-gradient(180deg, var(--w-primary-color) 60%, rgba(0, 0, 0, 0.1) 100%)`,
              left:
                clientData?.ui.position === "left"
                  ? !clientData?.ui.videoWidget.isActive
                    ? "35px"
                    : "140px"
                  : "unset",
              right:
                clientData?.ui.position === "right"
                  ? !clientData?.ui.videoWidget.isActive
                    ? "35px"
                    : "140px"
                  : "unset",
            },
          }}
          onClick={() => {
            navigate("/chat");
            pushEventToDataLayer(clientData?.id + " chat_opened", {
              event_category: "button",
              event_action: "click",
              event_label: "chat_button",
              page_path: "/chat",
              page_client_id: clientData?.id,
            });
          }}
          onMouseEnter={() => setHoveredChat(true)}
          onMouseLeave={() => setHoveredChat(false)}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            style={{
              width: "24px",
              height: "24px",
              fill: "#FFFFFF",
            }}
          >
            <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 10-2 0 1 1 0 002 0zm4 0a1 1 0 10-2 0 1 1 0 002 0zm3 1a1 1 0 100-2 1 1 0 000 2z"></path>
          </svg>
        </div>
      )}
      <Outlet />
    </div>
  );
};
export default Home;
