import React, { useEffect, useRef, useState } from "react";
import { ClientData, FloorplanType } from "./Types";
import Tour3D from "./Tour3D";
import GetInTouch from "./GetInTouch";
import BookTour from "./BookTour.tsx";
import ListOfFloorplans from "./ListOfFloorplans.tsx";
import FloorplanDetails from "./FloorplanDetails.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import AskQuestion from "./AskQuestion.tsx";
import WidgetLayout from "./WidgetLayout.tsx";
import PageNotFound from "./404.tsx";

const VideoWidget = ({ clientData }: { clientData?: ClientData }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isLg = windowSize.width >= 1024;
  const isMd = windowSize.width >= 768 && windowSize.width < 1024;
  const isSm = windowSize.width < 768;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //floorplans
  const [detailsData, setDetailsData] = useState<FloorplanType | null>(null);

  const [bedFilter, setBedFilter] = useState(0);
  const [bathFilter, setBathFilter] = useState(0);
  const [priceFilter, setPriceFilter] = useState<number[]>([
    clientData
      ? Math.round(clientData?.property.floorplans.range.min * 1.2)
      : 1000,
    clientData
      ? Math.round(clientData?.property.floorplans.range.max / 1.2)
      : 8000,
  ]);

  const scrollPositionRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBedFilter = (value: number) => setBedFilter(value);
  const handleBathFilter = (value: number) => setBathFilter(value);
  const handlePriceFilter = (value: number[]) => setPriceFilter(value);

  const showDetails = (data: FloorplanType) => {
    setDetailsData(data);
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop;
    }
    navigate(`/video/floorplans/floorplan/${data.name}`);
  };

  const handleSetData = (id: string) => {
    const floorplan = clientData?.property.floorplans.data.find(
      (el) => el.name === id
    );
    if (floorplan) setDetailsData(floorplan);
  };

  const handleResetDetailsData = () => {
    setDetailsData(null);
  };

  useEffect(() => {
    // Restore scroll position when returning to the list view
    if (location.pathname === "/video/floorplans" && containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [location]);

  if (!clientData) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<WidgetLayout clientData={clientData} />} />

      <Route
        path="/floorplans"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <ListOfFloorplans
              floorplans={clientData?.property.floorplans.data.sort(
                (a: FloorplanType, b: FloorplanType) =>
                  a.price.min - b.price.min
              )}
              showDetails={showDetails}
              bedFilter={bedFilter}
              bathFilter={bathFilter}
              priceFilter={priceFilter}
              isSm={isSm}
              isMd={isMd}
              scrollRef={containerRef}
              handlePriceFilter={handlePriceFilter}
              handleBathFilter={handleBathFilter}
              handleBedFilter={handleBedFilter}
              clientData={clientData}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <FloorplanDetails
              data={detailsData}
              setData={handleSetData}
              clientData={clientData}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id/book"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <BookTour
              apartmentData={detailsData}
              handleResetApartmentData={handleResetDetailsData}
              calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
              bookGuidedTour={clientData.ui.videoWidget.bookGuidedTour}
              isLg={isLg}
              isMd={isMd}
              setData={handleSetData}
              clientId={clientData.id}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id/ask-question"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <AskQuestion
              clientData={clientData}
              apartmentName={detailsData?.name}
              setData={handleSetData}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/book-tour"
        element={
          <WidgetLayout clientData={clientData}>
            {clientData.ui.videoWidget.bookSelfTour ? (
              <></>
            ) : (
              <BookTour
                calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
                isLg={isLg}
                isMd={isMd}
              />
            )}
          </WidgetLayout>
        }
      />
      <Route
        path="/book-guided-tour"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <BookTour
              apartmentData={detailsData}
              handleResetApartmentData={handleResetDetailsData}
              calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
              bookGuidedTour={clientData.ui.videoWidget.bookGuidedTour}
              isLg={isLg}
              isMd={isMd}
              setData={handleSetData}
              clientId={clientData.id}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/get-in-touch"
        element={
          <WidgetLayout clientData={clientData}>
            <GetInTouch clientData={clientData} isLg={isLg} />
          </WidgetLayout>
        }
      />
      {clientData.property.tour3d.isActive && (
        <Route
          path="/tour-3d"
          element={
            <WidgetLayout clientData={clientData}>
              <Tour3D clientData={clientData} isLg={isLg} isSm={isSm} />
            </WidgetLayout>
          }
        />
      )}
      <Route path="*" element={<PageNotFound clientData={clientData} />} />
    </Routes>
  );
};

export default VideoWidget;
