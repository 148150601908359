import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonFeed({
  isLg,
  isMd,
}: {
  isLg: boolean;
  isMd: boolean;
}) {
  return (
    <Stack
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        {(isLg || isMd) && (
          <Skeleton
            variant="rectangular"
            width="33%"
            height={200}
            animation="wave"
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        {(isLg || isMd) && (
          <Skeleton
            variant="rectangular"
            width="33%"
            height={200}
            animation="wave"
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={isLg || isMd ? "33%" : "50%"}
          height={200}
          animation="wave"
        />
        {(isLg || isMd) && (
          <Skeleton
            variant="rectangular"
            width="33%"
            height={200}
            animation="wave"
          />
        )}
      </div>
    </Stack>
  );
}
