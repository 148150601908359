import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const TimeSlotSkeleton = () => {
  return (
    <Stack spacing={-2} sx={{ marginTop: "-15px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0px, 1fr))",
          gap: "10px",
        }}
      >
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0px, 1fr))",
          gap: "10px",
        }}
      >
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0px, 1fr))",
          gap: "10px",
        }}
      >
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0px, 1fr))",
          gap: "10px",
        }}
      >
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
        <Skeleton
          sx={{ padding: "10px 5px", borderRadius: "5px" }}
          animation="wave"
          height={65}
        />
      </Box>
    </Stack>
  );
};

export default TimeSlotSkeleton;
