export const widgetStyle: React.CSSProperties = {
  position: "absolute",
  bottom: "15px",
  transition: "all 0.2s ease-in-out 0.1s",
  width: "130px",
  height: "130px",
  fontSize: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  overflow: "hidden",
  overscrollBehavior: "contain",
};

export const shapeWidgetStyle: React.CSSProperties = {
  WebkitClipPath:
    "polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13%, 96% 13.3%, 98% 14%, 99% 15%, 99.8% 17%, 100% 18%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%)",
  clipPath:
    "polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13.5%, 96% 13.8%, 98% 14.9%, 99% 16.1%, 99.8% 18%, 100% 19.3%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%)",
};

export const hoverStyle: React.CSSProperties = {
  transform: "scale(1.1)",
};

export const modalStyle: React.CSSProperties = {
  boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 20px",
  borderRadius: "24px",
  right: "unset",
  bottom: "20px",
  transition:
    "all cubic-bezier(0, 1.2, 1, 1) 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s",
  position: "fixed",
  zIndex: 2147483647,
  userSelect: "none",
  overscrollBehavior: "contain",
  fontWeight: "normal",
  fontSize: "16px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

export const paragraphStyle: React.CSSProperties = {
  margin: "14px",
};

export const chatStyle: React.CSSProperties = {
  color: "#000000",
  position: "absolute",
  bottom: "22px",
  transition: "all 0.2s ease-in-out 0.1s",
  width: "60px",
  height: "60px",
  borderRadius: "100%",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.4)",
  fontSize: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export const messageBlock: React.CSSProperties = {
  background: "#f2f2f2",
  padding: "10px 15px",
  borderRadius: "10px",
  color: "black",
};

export const kf1 = `
@keyframes widgetloader {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  25% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  75% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
`;

export const kf2 = `
  @keyframes widgetloader-2 {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
`;

export const kf3 = `
  @keyframes border-disappear {
    to {
      border-color: transparent;
    }
  }
`;

export const kf4 = `
  @keyframes border-turns-primary-from-white {
    to {
      border: 5px solid var(--w-primary-color);
      border-spacing: 0;
      background-color: var(--w-primary-color);
    }
  }
`;

export const kf5 = `
  @keyframes ping {
    0% {
      transform: scale(0.1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.2;
    }
    80% {
      transform: scale(1.2);
      opacity: 0.1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
 }
`;

export const kf6 = `
  @keyframes wh-returns-original {
    to {
      width: 100%;
      height: 100%;
    }
 }
`;

export const itemFilter: React.CSSProperties = {
  background: "rgba(21,27,83,.04)",
  borderRadius: "6px",
  padding: "7px",
  minWidth: "80px",
  fontSize: "14px",
  textAlign: "center",
  color: "#201f1e",
  margin: "4px",
  transition: "all .2s linear 0s",
  cursor: "pointer",
};

export const backdropFilterStyle: React.CSSProperties = {
  background: "var(--w-primary-color)",
  zIndex: 1,
  filter: "opacity(50%) grayscale(30%) brightness(0.6)",
};

export const formLabelStyle: React.CSSProperties = {
  fontWeight: "400",
  fontSize: "15px",
};
export const formFieldStyle: React.CSSProperties = {
  height: "40px",
  marginTop: "5px",
  marginBottom: "10px",
  paddingLeft: "10px",
  fontWeight: "300",
  fontSize: "14px",
  borderRadius: "5px",
  border: "2px solid var(--w-tertiary-color",
};
