import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import CalendarDay from "./CalendarDay";
import TimeSlot from "./TimeSlot";
import BookingForm from "./BookingForm";
import { toast } from "react-toastify";
import DateSkeleton from "./DateSkeleton";
import TimeSlotSkeleton from "./TimeSlotSkeleton";
import { FloorplanType } from "./Types";
import { useLocation } from "react-router-dom";

const BookGuidedTour = ({
  apartmentDetails,
  handleResetApartmentData,
  isMd,
  isLg,
  clientId,
  timezone,
}: {
  apartmentDetails?: FloorplanType | null;
  handleResetApartmentData?: () => void;
  isMd: boolean;
  isLg: boolean;
  clientId?: string | number;
  timezone?: string;
}) => {
  const [availableDays, setAvailableDays] = useState<string[]>();
  const [availableTimes, setAvailableTimes] = useState<string[] | null>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [isChosenDate, setIsChosenDate] = useState(false);
  const [timeSlot, setTimeSlot] = useState<string>("");
  const [isSelectedDate, setIsSelectedDate] = useState({
    id: -1,
    state: false,
  });
  const [isSelectedTime, setIsSelectedTime] = useState({
    id: -1,
    state: false,
  });
  const [messageForTimeSlots, setMessageForTimeSlots] = useState<{
    status: "info" | "error" | "";
    message: string;
  }>({ status: "", message: "" });
  const location = useLocation();

  useEffect(() => {
    const getDays = async () => {
      const startDate = dayjs().format("L");
      const endDate = dayjs().add(30, "day").format("L");
      await axios
        .get(`${process.env.REACT_APP_API_URL}/getDays`, {
          params: {
            smpClientId: clientId,
            startDate: startDate,
            endDate: endDate,
          },
        })
        .then((result) => setAvailableDays(result.data))
        .catch((err) => {
          console.log(err);
          setAvailableDays([]);
          toast.error("Error occurred. Please try again later...");
        });
    };
    getDays();
    if (handleResetApartmentData) handleResetApartmentData();
  }, [clientId, handleResetApartmentData, location]);

  const parseIsoTime = (timeString: string) => {
    if (selectedDate) {
      const utcDateString = new Date(
        `${selectedDate}, ${timeString} ${timezone}`
      ).toUTCString(); //api returns from MST time zone, gmt-6

      const newDate = new Date(utcDateString); //to extract year, month, day etc. needs to be Date type

      // Function to format numbers to two digits
      const pad = (num) => String(num).padStart(2, "0");

      const formattedIsoDate = `${newDate.getUTCFullYear()}-${pad(
        newDate.getUTCMonth() + 1
      )}-${pad(newDate.getUTCDate())}T${pad(newDate.getUTCHours())}:${pad(
        newDate.getUTCMinutes()
      )}:${pad(newDate.getUTCSeconds())}.000Z`;

      return formattedIsoDate;
    }
  };

  const isTimeWithinRange = (timeString) => {
    const [time, modifier] = timeString.split(" "); // Splits "08:15 AM" into "08:15" and "AM"
    let [hours, minutes] = time.split(":").map(Number); // Splits "08:15" into hours and minutes

    // Convert to 24-hour format
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0; // 12 AM is midnight
    }

    // Create Date object for the current date with the provided time
    const now = new Date();
    const comparisonTime = new Date();
    comparisonTime.setHours(hours, minutes, 0, 0);

    // Check if the comparison time is greater than current time
    const isGreaterThanCurrent = comparisonTime > now;

    // Check if comparison time is at least 4 hours greater than current time
    const fourHoursLater = new Date(now.getTime() + 4 * 60 * 60 * 1000); // Current time + 4 hours
    const isAtLeast4HoursLater = comparisonTime > fourHoursLater;

    return isGreaterThanCurrent && isAtLeast4HoursLater;
  };

  const getTimes = async (currentDate: string) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/getTimes`, {
        params: {
          smpClientId: clientId,
          appointmentDate: currentDate,
        },
      })
      .then((result) => {
        if (currentDate === dayjs().format("L")) {
          const validTimes = result.data.filter(isTimeWithinRange);
          if (validTimes.length === 0) {
            setAvailableTimes(null);
            setMessageForTimeSlots({
              status: "info",
              message:
                "No available time slots for today. Please try another day.",
            });
            toast.info("There are no available time slots for today.");
          } else setAvailableTimes(validTimes);
        } else {
          setAvailableTimes(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setAvailableTimes(null);
        setMessageForTimeSlots({
          status: "error",
          message: "An error occured while fetching available time slots.",
        });
        toast.error("Error occurred. Please try again later...");
      });
  };

  const handleSelectDate = async (id: number, currentDate: string) => {
    setAvailableTimes([]);
    setIsSelectedTime({ id: -1, state: false });
    setIsSelectedDate({ id, state: true });
    setSelectedDate(currentDate);
    setIsChosenDate(false);
    setTimeSlot("");
    await getTimes(currentDate);
  };

  const handleSelectTimeSlot = (id: number, currentTimeSlot: string) => {
    setIsSelectedTime({ id, state: true });
    setTimeSlot(currentTimeSlot);
    setIsChosenDate(false);
  };

  const handleSetAppointmentDate = () => {
    const isoDateTime = parseIsoTime(timeSlot);
    setSelectedDate(isoDateTime);
    setIsChosenDate(true);
  };

  const handleResetAfterSubmittingForm = () => {
    setIsSelectedDate({ id: -1, state: false });
    setIsSelectedTime({ id: -1, state: false });
    setTimeSlot("");
    setIsChosenDate(false);
    setSelectedDate("");
    if (handleResetApartmentData) handleResetApartmentData();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "11px 15px",
        overflowY: "scroll",
        scrollbarWidth: "thin",
        scrollbarColor: "#cfcfcf transparent",
        overscrollBehavior: "contain",
        position: "relative",
      }}
    >
      <div
        style={{
          fontSize: "20px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
          color: "#201f1e",
        }}
      >
        Schedule a tour
      </div>
      <div
        style={{
          fontSize: "15px",
          fontWeight: "300",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: "#000",
          marginTop: "5px",
        }}
      >
        Choose a date and time to start your tour.
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "5px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <div
          style={{ fontWeight: "500", paddingLeft: "5px", fontSize: "15px" }}
        >
          Day of your tour
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "5px",
            width: "100%",
            overflowY: "hidden",
            overflowX: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#cfcfcf transparent",
            overscrollBehavior: "contain",
            paddingBottom: isMd || isLg ? "5px" : "10px",
          }}
        >
          {availableDays ? (
            availableDays.length > 0 ? (
              availableDays.map((day, id) => (
                <CalendarDay
                  date={day}
                  id={id}
                  handleSelectDate={handleSelectDate}
                  isSelected={isSelectedDate}
                  key={id}
                />
              ))
            ) : (
              <div style={{ fontSize: "15px", color: "red" }}>
                An error occured while fetching available days.
              </div>
            )
          ) : (
            <DateSkeleton />
          )}
        </div>
      </div>
      {isSelectedDate.state && !isChosenDate && (
        <div
          style={{
            padding: "5px",
            // display: availableTimes ? "block" : "none",
          }}
        >
          <div
            style={{
              fontWeight: "500",
              margin: "10px 0px",
              paddingLeft: "5px",
              fontSize: "15px",
            }}
          >
            Start of your tour
          </div>
          <div
            style={{
              display:
                availableTimes && availableTimes.length > 0 ? "grid" : "block",
              gridTemplateColumns: "repeat(4, minmax(0px, 1fr))",
              gap: "10px",
              padding: "5px",
              width: "100%",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#cfcfcf transparent",
              overscrollBehavior: "contain",
              paddingBottom: isSelectedTime.state
                ? isLg
                  ? "55px"
                  : "50px"
                : isLg
                ? "5px"
                : "10px",
            }}
          >
            {availableTimes &&
              availableTimes.length > 0 &&
              availableTimes.map((timeSlot, id) => (
                <TimeSlot
                  time={timeSlot}
                  handleSelectTimeSlot={handleSelectTimeSlot}
                  isSelected={isSelectedTime}
                  id={id}
                  key={id}
                />
              ))}

            {availableTimes && availableTimes.length === 0 && (
              <TimeSlotSkeleton />
            )}

            {!availableTimes && (
              <div
                style={{
                  fontSize: "15px",
                  color:
                    messageForTimeSlots.status === "info" ? "#3498db" : "red",
                }}
              >
                {messageForTimeSlots.message}
              </div>
            )}
          </div>
        </div>
      )}
      {isSelectedTime.state && (
        <div
          style={{
            display: isChosenDate ? "none" : "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            style={{
              marginTop: "5px",
              width: "90%",
              border: "1px solid var(--w-primary-color)",
              background: "var(--w-primary-color)",
              color: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px",
              position: "fixed",
              bottom: isLg ? "5px" : "65px",
            }}
            onClick={handleSetAppointmentDate}
            className="w-primary-button"
          >
            Continue
          </button>
        </div>
      )}
      {
        <div style={{ display: isChosenDate ? "block" : "none" }}>
          <div
            style={{
              paddingLeft: "10px",
              marginTop: "10px",
              width: "150px",
            }}
          >
            <div
              style={{
                display: isChosenDate ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 5px",
                borderRadius: "5px",
                background: "var(--w-primary-color)",
                color: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px",
                textAlign: "center",
                transform: isChosenDate
                  ? "translate(0%, 0%)"
                  : "translate(20%, 10%)",
                transition: "all 0.3s ease-out",
                opacity: isChosenDate ? "1" : "0",
              }}
            >
              {timeSlot}
            </div>
          </div>
          {isChosenDate && (
            <BookingForm
              appointmentDate={selectedDate}
              apartmentDetails={apartmentDetails}
              handleResetAfterSubmittingForm={handleResetAfterSubmittingForm}
              clientId={clientId}
            />
          )}
        </div>
      }
    </div>
  );
};

export default BookGuidedTour;
