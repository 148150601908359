import React from "react";
import WidgetLayout from "./WidgetLayout.tsx";
import { ClientData } from "./Types";
import { useNavigate } from "react-router-dom";

const PageNotFound = ({ clientData }: { clientData?: ClientData }) => {
  const navigate = useNavigate();
  return (
    <div>
      <WidgetLayout clientData={clientData}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80%",
            fontSize: "25px",
            color: "rgba(0,0,0,0.5)",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: "60px", fontWeight: "550" }}>404</div>
          Oops, broken link
          <div
            onClick={() => navigate("/video")}
            style={{
              fontSize: "18px",
              marginTop: "15px",
              color: "#2d8ae0",
              cursor: "pointer",
            }}
          >
            Go back
          </div>
        </div>
      </WidgetLayout>
    </div>
  );
};

export default PageNotFound;
