import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { useParams } from "react-router-dom";
import BookGuidedTour from "./BookGuidedTour";
import { FloorplanType } from "./Types";

const BookTour = ({
  apartmentData,
  calendlyUrl,
  bookGuidedTour,
  isMd,
  isLg,
  setData,
  clientId,
  handleResetApartmentData,
}: {
  apartmentData?: FloorplanType | null;
  calendlyUrl?: string;
  bookGuidedTour?: {
    isActive: boolean;
    timezone?: string;
  };
  isMd: boolean;
  isLg: boolean;
  setData?: (id: string) => void;
  clientId?: string | number;
  handleResetApartmentData?: () => void;
}) => {
  const { id } = useParams();

  useEffect(() => {
    if (id && setData) setData(id);
  }, [id, setData]);

  return (
    <div
      className="w-menu-container"
      style={{
        paddingBottom: !isLg ? "64px" : "unset",
      }}
    >
      {bookGuidedTour && bookGuidedTour.isActive ? (
        <BookGuidedTour
          apartmentDetails={apartmentData}
          handleResetApartmentData={handleResetApartmentData}
          isMd={isMd}
          isLg={isLg}
          clientId={clientId}
          timezone={bookGuidedTour.timezone}
        />
      ) : calendlyUrl ? (
        <InlineWidget
          url={calendlyUrl}
          pageSettings={{
            backgroundColor: "fff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "6b7280",
            textColor: "000",
            hideGdprBanner: true,
          }}
          styles={{
            height: "100vh",
            marginTop: isLg || isMd ? "-50px" : "0",
            marginLeft: isLg ? "-30px" : "0",
            marginBottom: isLg ? "5px" : "0",
          }}
          prefill={{
            customAnswers: {
              a1: "",
              a2: apartmentData?.name ? apartmentData.name : "",
            },
          }}
        />
      ) : (
        <div>Booking tour currently unavailable</div>
      )}
    </div>
  );
};

export default BookTour;
