import React from "react";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const CalendarDay = ({
  date,
  id,
  handleSelectDate,
  isSelected,
}: {
  date: string;
  id: number;
  handleSelectDate: (id: number, value: string) => void;
  isSelected: { id: number; state: boolean };
}) => {
  const currentMonth = new Date(date).getMonth();
  const currentDate = new Date(date).getDate();
  const currentDay = new Date(date).getDay();
  const monthName = monthNames[currentMonth];
  const dayName = dayNames[currentDay];

  return (
    <div
      style={{
        minWidth: "100px",
        borderRadius: "5px",
        background: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "14px 5px",
        cursor: "pointer",
        border:
          isSelected.id === id && isSelected.state
            ? "1px solid var(--w-primary-color)"
            : "none",
      }}
      onClick={() => {
        handleSelectDate(id, date);
      }}
    >
      <div style={{ fontSize: "16px", color: "#000" }}>{monthName}</div>
      <div style={{ fontSize: "42px", lineHeight: "48px", color: "#000" }}>
        {currentDate}
      </div>
      <div style={{ fontSize: "16px", color: "red", fontWeight: 300 }}>
        {dayName}
      </div>
    </div>
  );
};

export default CalendarDay;
