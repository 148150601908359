import React, { useState } from "react";

const Button = ({
  text,
  handleClick,
  icon,
  isActive,
  first,
  isBurgerMenu,
  additionalStyling,
}: {
  text: string;
  handleClick?: () => void;
  icon?: React.JSX.Element;
  isActive?: boolean;
  first: boolean;
  isBurgerMenu?: boolean;
  additionalStyling?: { [key: string]: string };
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <button
      style={{
        ...{
          position: "relative",
          width: "100%",
          border: `${
            isBurgerMenu
              ? "none"
              : `1px solid ${isActive ? "#fff" : "var(--w-primary-color)"}`
          }`,
          pointerEvents: "auto",
          transitionDuration: "150ms",
          padding: "5px 10px",
          borderRadius: isBurgerMenu ? "5px" : "9999px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isBurgerMenu ? "left" : "center",
          zIndex: isActive ? 2 : 0,
          background: isBurgerMenu
            ? isActive
              ? hovered
                ? "#dce5f2"
                : "#e8f0fc"
              : hovered
              ? "rgba(0,0,0,0.1)"
              : "none"
            : first
            ? "var(--w-primary-color)"
            : isActive
            ? "var(--w-primary-color)"
            : "#fff",
          color: isBurgerMenu
            ? "black"
            : first
            ? "#fff"
            : isActive
            ? "#fff"
            : "#000",
          cursor: "pointer",
        },
        ...(additionalStyling && additionalStyling),
      }}
      onClick={() => {
        if (handleClick) handleClick();
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {icon && <div style={{ padding: "0 2px" }}>{icon}</div>}
      <div
        style={{
          textAlign: isBurgerMenu ? "left" : "center",
          fontSize: "14px",
          fontWeight: 500,
          padding: isBurgerMenu ? 0 : "12px 4px",
        }}
      >
        {text}
      </div>
    </button>
  );
};

export default Button;
