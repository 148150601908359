import React from "react";

const TimeSlot = ({
  time,
  handleSelectTimeSlot,
  isSelected,
  id,
}: {
  time: string;
  handleSelectTimeSlot: (id: number, value: string) => void;
  id: number;
  isSelected: { id: number; state: boolean };
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 5px",
        borderRadius: "5px",
        background:
          isSelected.id === id && isSelected.state
            ? "var(--w-primary-color)"
            : "#fff",
        color: isSelected.id === id && isSelected.state ? "#fff" : "#000",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px",
        textAlign: "center",
        cursor: "pointer",
      }}
      onClick={() => handleSelectTimeSlot(id, time)}
    >
      {time}
    </div>
  );
};

export default TimeSlot;
