import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const DateSkeleton = () => {
  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={120}
        sx={{ borderRadius: "5px" }}
        animation="wave"
      />
    </Box>
  );
};

export default DateSkeleton;
