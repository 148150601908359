import { useCallback } from "react";

const useGTM = () => {
  const pushEventToDataLayer = useCallback((event, data) => {
    if (window.dataLayerSMP) {
      window.dataLayerSMP.push({
        event,
        ...data,
      });
    } else {
      console.warn("GTM or dataLayer not available");
    }
  }, []);
  return pushEventToDataLayer;
};

export default useGTM;
